import { useRef, useState } from "react";
import classes from "classnames";
import { isArrowDownKeyOrArrowLeftKey, isArrowUpKeyOrArrowRightKey } from "../../helpers/keys";
import { isNumberCharacter, isSingleAlphabetCharacter, isSpace } from "../../helpers/utils/string";
import PropTypes from "prop-types";

/*
  * @param props {{id: string, name: string, length: number, value: string, secret: boolean, autoFocus: boolean, inputMode: string, style: object, error: boolean, success: boolean, inputType: string, disabled: boolean, inputStyle: object, className: string, inputClassName: string, onChange: function, onComplete: function, onFocus: function, onBlur: function
   }}
 */
export default function PinInput (props) {
  //region :: Props
  const {
    id = '',
    name,
    length = 5,
    // value = "",
    secret,
    // autoFocus = false,
    inputMode = "numeric",
    // style,
    error = false,
    success = false,
    inputType = "number",
    disabled = false,
    inputStyle,
    className = "",
    inputClassName = "",
    onChange,
    onComplete,
    onFocus,
    onBlur,
  } = props;
  //endregion

  const LAST_INPUT_INDEX = length - 1;
  const [inputInFocus, setInputInFocus] = useState(-1);
  const [inputTextArray, setInputTextArray] = useState(Array.from({ length }, () => ""));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const refs = Array.from({ length }, () => useRef(null));

  const gotoNextInput = function () {
    if (inputInFocus < LAST_INPUT_INDEX) {
      refs[inputInFocus + 1].current.focus();
    }
  };

  const gotoPreviousInput = function () {
    if (inputInFocus > 0) {
      refs[inputInFocus - 1].current.focus();
    }
  };

  const isAllowedCharacter = function (char) {
    if (!isSpace(char)) {
      const mode = inputMode.toLowerCase();

      if (mode === "numeric") {
        console.log({ mode, char, isNumberCharacter: isNumberCharacter(char) });
        return isNumberCharacter(char);
      } else if (mode === "alphabet") {
        return isSingleAlphabetCharacter(char);
      } else if (mode === "alpha_numeric") {
        return isNumberCharacter(char) || isSingleAlphabetCharacter(char);
      }
    }
    return false;
  }

  function getValue (newTextArray) {
    const values = newTextArray || inputTextArray;
    return {
      value: values.join(""),
      values
    };
  }

  const handleChange = async (value, index, event) => {
    let newTextArray = [...inputTextArray];
    newTextArray[index] = "";

    if (value !== "Backspace") {
      if (isArrowUpKeyOrArrowRightKey(value)) {
        gotoNextInput();
      } else if (isArrowDownKeyOrArrowLeftKey(value)) {
        gotoPreviousInput();
      } else if (value.length === 1 && isAllowedCharacter(value)) {
        newTextArray[index] = value;

        await setInputTextArray(newTextArray);
        gotoNextInput();

        typeof onChange === "function" && onChange(name, getValue(newTextArray));

        if (index === LAST_INPUT_INDEX) {
          typeof onComplete === "function" && onComplete(name, getValue(newTextArray));
        }
      } else {
        event.preventDefault();
      }
    } else {
      await setInputTextArray(newTextArray);

      // if shift key is pressed don't go to previous input
      if (!event.shiftKey) {
        gotoPreviousInput();
      }

      typeof onChange === "function" && onChange(name, getValue(newTextArray));
    }
  };

  const handleBlur = () => {
    setInputInFocus(-1);
    typeof onBlur === "function" && onBlur(name, { ...getValue(inputTextArray), index: inputInFocus });
  };

  const handleFocus = (index) => {
    setInputInFocus(index);
    typeof onFocus === "function" && onFocus(name, { ...getValue(inputTextArray), index });
  };

  return <div className={"flex pin-input justify-content-between " + className}>
    {
      refs.map((ref, index) => {
        return <input
          id={id + (index > 0 ? index : "")}
          disabled={disabled}
          className={classes("form-control", inputClassName, { disabled, error, success, })}
          key={index}
          ref={ref}
          type={secret ? "password" : inputType}
          value={inputTextArray[index]}
          onBlur={handleBlur}
          inputMode={inputMode}
          onChange={() => {
          }}
          style={{ textAlign: 'center', ...inputStyle }}
          onPaste={(e) => {
            // console.log('paste', e.clipboardData.getData('text'));
            // handleChange(e.clipboardData.getData('text'), index);
            // navigator.clipboard.readText().then((clipText) => (paste.innerText = clipText));

            // trim the clipboard text to the inputs' length if the inputMode is numeric,
            // check if the clipboard text is a number, then split the text into an array and set the InputTextArray to the array

            const clipText = e.clipboardData.getData('text').trim();

            if (inputMode.toLowerCase() === "numeric" && Number.isNaN(Number(clipText))) {
              return;
            }
            setInputTextArray(clipText.split(""));
          }}
          onCopy={(e) => {
            // set clipboard text to the current input text
            e.clipboardData.setData('text', inputTextArray.join(""));
            // console.log('copy', e.clipboardData.getData('text'));
          }}
          onFocus={() => {
            handleFocus(index);
          }}
          onKeyDown={(e) => {
            handleChange(e.key, index, e);
          }}
        />
      })
    }
    <style jsx>
      {`
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      `}
    </style>
  </div>;
}

PinInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  inputMode: PropTypes.string,
  inputStyle: PropTypes.object,
  length: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  onFocus: PropTypes.func,
  secret: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
}
