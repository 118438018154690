export const isArrowKey = function (key) {
  return key === "ArrowUp" || key ===  "ArrowDown" || key === "ArrowRight" || key === "ArrowLeft"
};

/**
 *
 * @param key {string}
 * @returns {boolean}
 */
export const isArrowUpKey = function (key) {
  return key === "ArrowUp"
};

/**
 *
 * @param key {string}
 * @returns {boolean}
 */
export const isDownKey = function (key) {
  return key === "ArrowDown"
};

/**
 *
 * @param key {string}
 * @returns {boolean}
 */
export const isArrowRightKey = function (key) {
  return key === "ArrowRight"
};

/**
 *
 * @param key {string}
 * @returns {boolean}
 */
export const isArrowLeftKey = function (key) {
  return key === "ArrowLeft"
};

/**
 *
 * @param key {string}
 * @returns {boolean}
 */
export const isArrowUpKeyOrArrowRightKey = function (key) {
  return key === "ArrowRight" || key === "ArrowUp"
};

/**
 *
 * @param key {string}
 * @returns {boolean}
 */
export const isArrowDownKeyOrArrowLeftKey = function (key) {
  return key === "ArrowLeft" || key === "ArrowDown"
}

/**
 *
 * @param key {string}
 * @returns {boolean}
 */
export const isNumericKey = function (key) {
  return key >= 0 && key <= 9
}


export const isEnterKey = function (key) {
  return key === "Enter"
}
export const isBackSpace = function (key) {
  return key === "Backspace"
}

export const isDelete = function (key) {
  return key === "Delete"
}

export const isEscapeKey = function (key) {
  return key === "Escape"
}

export const isAllowedInNumbers = function (key) {
  return isNumericKey(key) || isArrowKey(key) || isEnterKey(key) ||
    isDelete(key) || isBackSpace(key) || key === "." || key === ","
}

export const isAllowedIntNumbers = function (key) {
  return isNumericKey(key) || isArrowKey(key) || isEnterKey(key) ||
    isDelete(key) || isBackSpace(key)
}
