import { useEffect, useState } from 'react';

export const useNetwork = () => {

  let navigatorOline = false;

  if (typeof window !== 'undefined') {
    navigatorOline = window.navigator.onLine;
  }


  const [isOnline, setNetwork] = useState(navigatorOline);
  const [message, setMessage] = useState('');

  const handleOnline = online => {
    setNetwork(navigatorOline);
    fakeReconnection();
  };

  const handleOffline = () => {
    setNetwork(navigatorOline);
    setMessage('Connection lost');
    resetAnimation();
  };

  const fakeReconnection = () => {
    setMessage('Attempting to reconnect');
    setTimeout(() => {
      setMessage('Reconnected');
      resetAnimation();
    }, 2000);
  };

  const resetAnimation = () => {
    setTimeout(() => {
      setMessage('');
    }, 2000);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      }
    };
  });
  return {
    isOnline,
    message,
  };
}

export default useNetwork;
