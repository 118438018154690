import PropTypes from 'prop-types';
import React from "react";
import { Button } from "reactstrap";
import * as classes from "classnames";
import { SpinningCircles } from "../LoadingRings/LoadingRings";
import { useTheme } from "../../hooks/useTheme";

export default function ProgressButton (props) {
  const {theme} = useTheme();
  let {
    loading,
    color = "primary",
    type = "button",
    className = "",
    fullWidth = false,
    block = false,
    alwaysShowLabel = false,
    disabled,
    label,
    onClick,
    children
  } = props;

  if (!block && fullWidth) {
    block = true;
  }

  let buttonProps = {
    type,
    ...((block === true) && { block }),
    color,
    className : classes(className, { disabled : disabled || loading }),
    ...(onClick && { onClick })
  }
  const Label = <span>{label ? label : children ? children : ''}</span>
  return (
    <Button {...buttonProps}>
      {(!loading) && Label}
      {(loading) && <span>
        <SpinningCircles color={theme === "dark" ? "gold-neue" : "gold"} size={20}/>{' '}
        {alwaysShowLabel && Label}
      </span>}
    </Button>);
}

ProgressButton.propTypes = {
  className : PropTypes.string,
  block : PropTypes.bool,
  color : PropTypes.string,
  label : PropTypes.string,
  fullWidth : PropTypes.bool,
  alwaysShowLabel : PropTypes.bool,
  loading : PropTypes.bool,
  disabled : PropTypes.bool,
  type : PropTypes.string,
  onClick : PropTypes.func
}
