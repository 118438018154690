const BASE_UNIT = "MILLISECONDS";

export const SECONDS = BASE_UNIT === "MILLISECONDS" ? 1000 : 1;

export const MINUTES = SECONDS * 60;

export const HOURS = MINUTES * 60;

export const DAYS = HOURS * 24;

export const WEEKS = DAYS * 7;

export const MONTHS = DAYS * 30;

export const YEARS = DAYS * 365;
