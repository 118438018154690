import { post } from "../../../api";

export default async function verifyRegisteredAccount ({ username, code }) {
  try {
    return await post('/signup/confirm-signup', { username, code });
  } catch (e) {
    throw e;
  }
}

export async function resendVerificationCode (username) {
  try {
    console.log({username})
    return await post('/signup/resend-confirmation-code', { username });
  } catch (e) {
    throw e;
  }
}

/**
 * Initiates OTP (One-Time Password) for the specified contact.
 *
 * @param {string} contact - The contact for which OTP needs to be initiated.
 * @param {string<'email'|'phone'>} contactType - The type of contact (e.g., email, phone number).
 *
 * @returns {Promise} - A Promise that resolves when OTP is successfully initiated.
 */
export async function initiateOTP (contact, contactType = "phone") {
  try {
    return await post('/otp/initiate', { contact, contactType });
  } catch (e) {
    throw e;
  }
}

/**
 * Verifies an OTP (One-Time Password) for a given contact.
 *
 * @param {string} sub - The contact to verify the OTP for.
 * @param {string} otp - The OTP to be verified.
 * @param {string<'email'|'phone'>} contactType - The type of contact (e.g., email, phone number).
 * @returns {Promise} - A Promise that resolves to the result of the verification.
 * @throws {Error} - If an error occurs during the verification process.
 */
export const verifyOTP = async (sub, otp, contactType = "phone") => {
  try {
    const response = await post('/otp/verify', { sub,  otp, contactType });
    return response.data;
  } catch (e) {
    throw e;
  }
}

/**
 * Resend OTP to the specified contact.
 *
 * @param {string} sub - The contact to resend OTP to.
 * @param {string<'email'|'phone'>} contactType - The type of contact (e.g., email, phone number).
 * @returns {Promise<any>} - A Promise that resolves with the result of the resend operation.
 * @throws {Error} - If an error occurs during the resend operation.
 */
export const resendOTP = async (sub, contactType = "phone") => {
  try {
    const response = await post('/otp/resend', { sub, contactType });
    return response.data;
  } catch (e) {
    throw e;
  }
}
