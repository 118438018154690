import { useEffect, useState } from "react";
import { DAYS, HOURS, MINUTES } from "../../config/time-constants";

const TIME_UNIT_STRINGS_EN = {
  day: {
    singular: 'day',
    plural: 'days',
  },
  hour: {
    singular: 'hour',
    plural: 'hours',
  },
  minute: {
    singular: 'minute',
    plural: 'minutes',
  },
  second: {
    singular: 'second',
    plural: 'seconds',
  },
}

function calculateTimeLeft (date) {
  return (new Date(date)).getTime() - (new Date()).getTime()
}

export default function CountdownTimer ({ date, className="", onTimeUp, showAll = false, localeString = TIME_UNIT_STRINGS_EN }) {
  const [timerComponents, setTimerComponents] = useState({
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
  });

  const timeLeft = calculateTimeLeft(date);




  useEffect(() => {
    const timer = setTimeout(() => {
      const timeLeft = calculateTimeLeft(date);

      if (timeLeft < 0) {
        typeof onTimeUp === 'function' && onTimeUp();
        return;
      }

      setTimerComponents({
        day: Math.floor(timeLeft / (DAYS * 24)),
        hour: Math.floor((timeLeft / (HOURS)) % 24),
        minute: Math.floor((timeLeft / MINUTES) % 60),
        second: Math.floor((timeLeft / 1000) % 60),
      });
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (timeLeft < 0) {
    typeof onTimeUp === 'function' && onTimeUp();
    return null;
  }

  const TIMER_COMPONENTS = Object.entries(timerComponents);
  const LAST_COMPONENT_INDEX = TIMER_COMPONENTS.length - 1;

  return (
    <span className={className}>
      {
        TIMER_COMPONENTS.map(([unit, value], index) => {
          if (value <= 0 && !showAll) {
            return null;
          }

          return (
            <span className=" font-weight-500 text-small" key={unit}>
              <span className={"time-unit-value"}>{value}</span>{' '}
              <span className={"time-unit text-muted"}>{
                value === 1 ? localeString[unit].singular : localeString[unit].plural
              }</span>{index < LAST_COMPONENT_INDEX ? ', ' : ''}
            </span>
          )
        })
      }
    </span>
  );
}
