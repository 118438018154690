import React, { useState, } from "react";
import { Col, Container, Row } from "reactstrap";
import classes from "classnames";

import PinInput from "../components/PinInput";
import { obscurePhone } from "../helpers/utils/string";
import { verifyOTP, resendOTP } from "../sections/register/endpoints";
import { handleNetworkError } from "../helpers/utils/http";
import moment from "moment";
import ProgressButton from "../components/LoadingButton/ProgressButton";
import { diceyDialog } from "../helpers/utils";
import { useNamedState } from "../hooks/useNamedState";
import CountdownTimer from "../components/CountdownTimer/CountdownTimer";

function calculateTimeToResend (expiryDate) {
  return moment(expiryDate).subtract(Date.now, "milliseconds");
}

function VerifyPhone (props) {
  let {
    phoneNumber = "",
    userDetails,
    message = "Your account has been created",
    title = "Verify your phone with OTP",
  } = props;

  const [otp, setOtp] = useState("");
  const [CAN_RESEND_OTP, setCanResendOTP] = useState(true);
  const [OTP_EXPIRED, setOTPExpired] = useState(false);

  const initialResendTime = userDetails.retryAt ? calculateTimeToResend(userDetails.retryAt) : null;

  const [resendTime, setResendTime] = useNamedState("resendTime", initialResendTime);

  const [loading, setLoading] = useState(false);

  async function verify () {
    try {
      setLoading(true);
      setCanResendOTP(false);
      const response = await verifyOTP(userDetails.userSub, otp)

      if (response) {
        diceyDialog({
          icon: "success",
          title: "Verification Successful",
          text: "Your phone number has been verified successfully.",
          handlePrimaryButton: () => {
            window.location.href = "/login";
          }
        })
        setLoading(false);
        console.log({ response })
        // const { codeDeliveryDetails } = response;
        // const { deliveryMedium, destination, retryMinutes, expiration, } = codeDeliveryDetails;
        //
        // const resendTime = moment(Expiration).subtract(moment(), "milliseconds");
        // setResendTime(resendTime);
      }
    } catch (e) {
      setLoading(false);
      handleNetworkError(e, {
        400: ({ code, message }) => {
          if (code === "ExpiredOTPError") {
            setOTPExpired(true);
            diceyDialog({
              icon: "error",
              title: "Error",
              text: "The OTP has expired. Please request a new one.",
              timer: null,
            });
            return;
          }
          diceyDialog({
            icon: "error",
            title: "Error",
            text: message,
            timer: null,
          });
        }
      }, "OTP_VERIFICATION_FAILED");
    }
  }

  async function resend () {
    try {
      setLoading(true);
      setResendTime(null);

      const response = await resendOTP(userDetails.userSub, "phone");

      if (response) {
        setLoading(false);
        const { retryMinutes, expiresAt } = response;
        setResendTime(calculateTimeToResend(expiresAt));
        setCanResendOTP(false);
      }

    } catch (e) {
      setLoading(false)
      handleNetworkError(e, {}, "OTP_VERIFICATION_FAILED");
    }
  }

  return <Container className=" welcome-container d-flex">
    <div className={classes(" text-center my-auto w-100", {})}>
      {/*<Row className="justify-content-center flex-column-reverse flex-md-row py-3 px-5">
        <Col sm={12} className={" bg-art"}>
          <img src="/img/backgrounds/v-email-i.svg" alt="Page not found artwork"/>
        </Col>
      </Row>*/}
      <Row className="justify-content-center flex-column-reverse flex-md-row py-3 px-5">
        <Col sm={12} className={" bg-art"}>
          <h3 className="hero-welcome  font-weight-600">{title}</h3>
        </Col>
      </Row>

      <small className="welcome-caption d-block mb-3 text-uppercase">
        {message}
      </small>
      <p className="welcome-caption text-small mb-0">
        Enter the code that has been sent to your phone - {obscurePhone(phoneNumber)} via sms.
      </p>

      <Row className="justify-content-center flex-column-reverse flex-md-row py-3 px-5">
        <Col md={4}>
          <PinInput
            name={"otp"}
            length={6}
            inputMode={"numeric"}
            inputType={"number"}
            inputStyle={{ height: "60px", maxWidth: "40px" }}
            inputFocusStyle={{ borderColor: "var(--input-focus-outline)" }}
            onChange={(name, { value }) => setOtp(value)}
          />
        </Col>
      </Row>
      <ProgressButton
        loading={loading}
        label={"Verify"}
        alwaysShowLabel={true}
        onClick={verify}
        className={classes("py-2 mb-3 mb-md-4 cur-pointer ")}
      />

      {
        CAN_RESEND_OTP ? <Row className="justify-content-center flex-column-reverse flex-md-row py-3 px-5">
          <Col>
            <p className={"text-small"}>Didn't received it?{' '}
              <ProgressButton
                loading={loading}
                alwaysShowLabel={true}
                label={"Resend"}
                color={"link-accent"}
                className={"link-accent px-1"} onClick={resend}/>
            </p>
          </Col>
        </Row> : null
      }

      {
        resendTime && !loading && !CAN_RESEND_OTP ? <Row className="justify-content-center flex-column-reverse flex-md-row py-3 px-5">
          <Col>
            <p className={"text-small"}>
              You can get a new code in{' '}
              <CountdownTimer className={"text-accent"} date={resendTime} onTimeUp={() => {
                setCanResendOTP(true);
              }}></CountdownTimer>
            </p>
          </Col>
        </Row> : null
      }

    </div>
  </Container>;
}

export default VerifyPhone;
